<template>
  <div>
    <div class="top_select" style="margin-bottom: 10px">
      <div class="top_select">
        <el-button
          type="primary"
          size="small"
          @click="handleClick()"
          :disabled="!$buttonStatus('qwhd_xz')"
          icon="Plus"
          >新增</el-button
        >
        <span style="font-size: 13px; color: red; margin-left: 10px"
          ><i
            class="iconfont icon-gantanhao1"
            style="font-size: 13px; margin-right: 4px"
          ></i
          >温馨提示：扫码绑定公司，用户可以通过查账小程序远程查看账目情况！</span
        >
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :height="contentStyleObj"
        v-loading="loading"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="55"
          align="center"
          fixed
        />
        <el-table-column prop="name" label="绑定人员" min-width="180">
          <template #default="scope">
            <div v-if="scope.row.name" class="div_box">
              <!-- <img :src="scope.row.avatar_url"> -->
              {{ scope.row.name }}
            </div>
            <div v-else>暂未授权</div>
          </template>
          </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template #default="scope">
            <el-button
              size="small"
              plain
              type="danger"
              @click="del(scope.row)"
              :disabled="!$buttonStatus('qwhd_sc')"
            >
              <el-icon><Delete /></el-icon><span> 删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogVisible"
      title="新增"
      width="30%"
    >
      <el-form>
        <el-form-item label="姓名：" label-width="120px">
          <el-input
            v-model="nickName"
            style="width: 200px"
            size="small"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号 ：" label-width="120px">
          <el-input
            v-model="tel"
            style="width: 200px"
            size="small"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="bind" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="[codeImg]"
  />
</template>
<script>
import {
  getBindWxUser,
  delWxComUser,
  wxCzComBindUser,
  getCompanyQrCode
} from "@/api/company";

export default {
  name: "bindApplt",

  data() {
    return {
      tableData: [],
      dialogVisible: false,
      nickName: "",
      tel: "",
      loading: false,
      contentStyleObj: {},
      comId: this.$store.getters["user/comInfo"].comId * 1,
      showImagePreview: false,
      codeImg:""
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(200);
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.loading = true;
      getBindWxUser({ comId: this.comId }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
        }
      });
    },
    handleClick() {
      getCompanyQrCode({comId:this.comId}).then(res=>{
        if(res.data.msg == 'success'){
          this.codeImg = "https://file.listensoft.net" + res.data.data
          this.showImagePreview = true;
        }
      })
      // this.nickName = "";
      // this.tel = "";
      // this.dialogVisible = true;
    },
    bind() {
      if (this.nickName == "") {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      } else if (this.tel == "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      } else if (!/^1[3456789]\d{9}$/.test(this.tel)) {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
        });
        return;
      } else {
        wxCzComBindUser({
          nickName: this.nickName,
          tel: this.tel,
          comId: this.comId,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.dialogVisible = false;
            this.$qzfMessage("绑定成功");
            this.getList();
          }
        });
      }
    },
    del(row) {
      this.$confirm("确认删除此绑定人员吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delWxComUser({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })

        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.common_dialog_box .common_title {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.fxk_div {
  width: 100%;
}
.el-dialog__body {
  padding-top: 0 !important;
}
.big_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
}
.box {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}
.image {
  width: 30px;
  height: 30px;
}
:deep(.el-checkbox__label) {
  display: flex !important;
  justify-content: center !important;
  height: 15px !important;
}
.choose_img {
  border-radius: 50px;
}
.div_box {
  display: flex;
  align-items: center;
  img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
