<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="一键排序" v-model="dialogVisible" width="450px">
    <el-form label-position="right" label-width="120px" ref="dataForm" size="small" :rules="rules" :model="temp">
      <el-form-item label="前缀：" prop="pre">
        <el-input v-model="temp.pre" style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item label="编码后缀长度：" prop="len">
        <el-input v-model.number="temp.len" style="width: 240px"></el-input>
      </el-form-item>
      <div style="margin-left: 10px; color: var(--themeColor, #17a2b8)">
        【示例】：{{ symbol }}
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save()" size="small">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { sortCom } from "@/api/company";
import { ElMessage } from "element-plus";
const props = defineProps({
  automatic: {
    type: Number,
    default: 0
  },
  status:{
    type: Number,
    default: 2
  }
})
const symbol = computed(() => {
  if (Number.isInteger(temp.value.len)) {
    let str = "1".padStart(temp.value.len, "0");
    return temp.value.pre + str;
  }
});
const emit = defineEmits();
const dialogVisible = ref(false);
const temp = ref({
  comIds: [],
  pre: "A",
  len: 5,
  autoMatic: props.automatic,
  status: props.status
});
const rules = ref({
  pre: [{ required: true, message: "请输入前缀", trigger: "blur" }],
  len: [{ required: true, message: "请输入长度", trigger: "blur" }],
});
const init = (ids) => {
  dialogVisible.value = true;
  temp.value.comIds = ids;
};
const save = () => {
  if (!temp.value.pre || !temp.value.len) {
    ElMessage.warning("请输入前缀和长度");
    return;
  }
  if (
    isNaN(Number(temp.value.len)) ||
    Number(temp.value.len) < 1 ||
    Number(temp.value.len) > 10
  ) {
    ElMessage.warning("请输入长度范围为1-10的数字");
    return;
  }
  sortCom(temp.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("排序成功");
      emit("success");
      dialogVisible.value = false;
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
